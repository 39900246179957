import { useRef, useState } from 'react';

import classNames from 'classnames';

import {
  BpkButtonV2,
  BUTTON_TYPES,
  SIZE_TYPES,
} from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import ChevronDownIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-down';
import ChevronRightIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/chevron-right';

import ArticleCard from '../ArticleCard';

import withBreakpointConfig, {
  type WithBreakpointConfigProps,
} from './withBreakpointConfig';

import type { ArticleGridProps } from '@skyscanner-internal/falcon-shared-types/types/ArticleGridProps';

import STYLES from './ArticleGrid.module.scss';

const AlignedChevronDownIcon = withButtonAlignment(
  withRtlSupport(ChevronDownIcon),
);

const AlignedChevronRightIcon = withButtonAlignment(
  withRtlSupport(ChevronRightIcon),
);

const SHORT_RATIO = 288 / 218;
const TALL_RATIO = 288 / 329;

export const ArticleGrid = ({
  articles,
  breakpointConfig,
  nextPageHref,
  nextPageLabel,
  showMoreLabel,
}: WithBreakpointConfigProps & ArticleGridProps) => {
  const [numSections, setNumSections] = useState(1);
  const gridRef = useRef<HTMLDivElement>(null);

  const { perSection } = breakpointConfig;
  const maxItemsToShow = numSections * perSection;
  const allItemsShown = maxItemsToShow >= articles.length;

  const handleShowMore = () => {
    // Set focus to last item in current set
    const itemEls = gridRef.current?.children;
    const lastItemEl = itemEls?.item(maxItemsToShow - 1) as HTMLDivElement;
    lastItemEl.querySelectorAll('a')[1]?.focus();

    // Show the next sections
    setNumSections(numSections + 1);
  };

  // We use this to use pure CSS to show/hide the initial sections, to avoid content flashing
  const firstSection = numSections === 1;

  return (
    <div
      className={classNames([
        STYLES.Container,
        firstSection && STYLES.Container__HandleFirstSection,
      ])}
    >
      <div className={STYLES.Grid} ref={gridRef}>
        {articles.map((article, index) => (
          <div
            key={article.articleHref}
            className={classNames(STYLES.Item, {
              [STYLES.Item__Hidden]: index >= maxItemsToShow,
              [STYLES.Item__Inserted]: index >= perSection,
            })}
          >
            <div className={STYLES.ItemTall}>
              <ArticleCard
                {...article}
                aspectRatio={TALL_RATIO}
                variant="gridArticle"
              />
            </div>
            <div className={STYLES.ItemShort}>
              <ArticleCard
                {...article}
                aspectRatio={SHORT_RATIO}
                variant="gridArticle"
              />
            </div>
          </div>
        ))}
      </div>

      <div
        className={classNames(STYLES.ButtonContainer, {
          [STYLES.ButtonContainer__Hidden]: allItemsShown,
        })}
      >
        <BpkButtonV2
          type={BUTTON_TYPES.link}
          size={SIZE_TYPES.small}
          onClick={handleShowMore}
        >
          {showMoreLabel} <AlignedChevronDownIcon />
        </BpkButtonV2>
      </div>

      {nextPageHref && (
        <div
          className={classNames(STYLES.ButtonContainer, {
            [STYLES.ButtonContainer__Hidden]: !allItemsShown,
          })}
        >
          <BpkButtonV2
            href={nextPageHref}
            type={BUTTON_TYPES.link}
            size={SIZE_TYPES.small}
          >
            {nextPageLabel} <AlignedChevronRightIcon />
          </BpkButtonV2>
        </div>
      )}
    </div>
  );
};

export default withBreakpointConfig(ArticleGrid);
